import React, { useContext } from 'react'
import styled from 'styled-components'

import { StateContext } from 'context/StateContext'

const RegisterButton = () => {
  const { hideRegister } = useContext(StateContext)

  if (hideRegister) return null

  return (
    <Link
      href="https://wsjmagazinetheonemay.splashthat.com/"
      target="_blank"
      rel="noreferrer noopener"
    >
      Join Us
    </Link>
  )
}

const Link = styled.a`
  margin-top: 0;
  border: 1px solid var(--color-black);
  background-color: #e4e2df;
  padding: 1.0625rem 2.6875rem;
  font-family: Blair ITC Std;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1.230769231;
  text-transform: uppercase;
  color: var(--color-black);
  cursor: pointer;
  transition: opacity ease-in-out 0.3s;
  letter-spacing: 0.2rem;

  &:hover {
    background-color: #e4e2df;
    opacity: 0.7;
  }
`

export default RegisterButton
