import React, { useContext, useState, useEffect, useRef } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

import { StateContext, getNow, VIDEO_TIMESTAMP } from 'context/StateContext'
import breakpoints from 'constants/breakpoints'

import PlayButtonSvg from './video-player-play.svg'
import VolumeOffButtonSvg from './video-player-volume-off.svg'
import PosterJpg from './poster.jpg'

const offset = (getNow() - VIDEO_TIMESTAMP) / 1000

let videos = [
  'https://d3mjor9df6lojn.cloudfront.net/MYTv02/MYTv02.m3u8',
  'https://d3mjor9df6lojn.cloudfront.net/TheOneV15/TheOneV15.m3u8',
]

const Video = () => {
  const playerReference = useRef()
  const { isLive, setIsCurtainDown } = useContext(StateContext)

  const [isReady, setIsReady] = useState(false)
  const [error, setError] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [playlistIndex, setPlaylistIndex] = useState(0)
  const [shouldShowPlayButton, setShouldShowPlayButton] = useState(false)
  const [timeStarted, setTimeStarted] = useState(0)

  const handleReady = () => {
    setIsReady(true)
  }

  const handlePlay = () => {
    setTimeStarted(Math.round(playerReference.current.getCurrentTime()))
    if (playlistIndex === 0) {
      ReactGA.event({
        category: 'Video',
        label: 'Ad',
        action: 'Started',
      })
    } else {
      ReactGA.event({
        category: 'Video',
        label: 'Episode',
        action: 'Started',
      })
      if (Math.round(playerReference.current.getCurrentTime()) > 1) {
        ReactGA.event({
          category: 'Video',
          label: 'Episode',
          action: 'Arrived late',
          value: Math.round(playerReference.current.getCurrentTime()),
        })
      }
    }
    setIsPlaying(true)
    setShouldShowPlayButton(false)
  }

  const handlePause = () => {
    setIsPlaying(false)
    if (playlistIndex === 0) {
      ReactGA.event({
        category: 'Video',
        label: 'Ad',
        action: 'Paused',
        value:
          Math.round(playerReference.current.getCurrentTime()) - timeStarted,
      })
    } else {
      ReactGA.event({
        category: 'Video',
        label: 'Episode',
        action: 'Paused',
        value:
          Math.round(playerReference.current.getCurrentTime()) - timeStarted,
      })
    }
  }

  const handleError = (title, object) => {
    setShouldShowPlayButton(true)
    // if object is undefined probably autoplay error,
    // and if error is not fatal don't stop playing
    // [ ex: 'buffering' pausing on slow internet throws an error, but is not fatal ]

    if (object === undefined || object.fatal === true) {
      setError(true)
      setIsPlaying(false)
    } else {
      // If error isn't fatal, try to play the video again
      // setIsPlaying(false)
      // setTimeout(() => {
      //   setIsPlaying(true)
      // }, 300)
    }
  }

  const handleUnmute = () => {
    setIsMuted(false)
  }

  const handleDuration = (duration) => {
    if (playlistIndex > 0) {
      if (offset && offset > 5 && offset < duration) {
        playerReference.current.seekTo(offset, 'seconds')
      }
    }
    setTimeout(handlePlay, 2000)
    handlePlay()
  }

  const handleEnded = () => {
    if (videos[playlistIndex + 1]) {
      ReactGA.event({
        category: 'Video',
        label: 'Ad',
        action: 'Finished',
        value:
          Math.round(playerReference.current.getCurrentTime()) - timeStarted,
      })
      setPlaylistIndex(playlistIndex + 1)
    } else {
      ReactGA.event({
        category: 'Video',
        label: 'Epsiode',
        action: 'Finished',
        value:
          Math.round(playerReference.current.getCurrentTime()) - timeStarted,
      })
      setIsCurtainDown(true)
    }
  }

  useEffect(() => {
    if (error) {
      setIsPlaying(false)
    }
  }, [error])

  useEffect(() => {
    if (playlistIndex === 0) return
    setIsPlaying(true)
  }, [playlistIndex])

  useEffect(() => {
    window.addEventListener('beforeunload', logWatched)
    return () => {
      window.removeEventListener('beforeunload', logWatched)
    }
  })

  const logWatched = () => {
    if (playlistIndex === 0) {
      ReactGA.event({
        category: 'Video',
        label: 'Ad',
        action: 'Exited Window',
        value:
          Math.round(playerReference.current.getCurrentTime()) - timeStarted,
      })
    } else {
      ReactGA.event({
        category: 'Video',
        label: 'Episode',
        action: 'Exited Window',
        value:
          Math.round(playerReference.current.getCurrentTime()) - timeStarted,
      })
    }
  }

  if (!isLive) return null

  return (
    <Container>
      <ReactPlayer
        className="react-player"
        width="100%"
        height="100%"
        volume={1}
        loop={false}
        playsinline
        pip={false}
        ref={playerReference}
        url={videos[playlistIndex]}
        config={{ file: { poster: PosterJpg } }}
        //
        playing={isPlaying}
        muted={isMuted}
        //
        onReady={handleReady}
        onDuration={handleDuration}
        onPlay={handlePlay}
        onPause={handlePause}
        onError={handleError}
        onEnded={handleEnded}
      />

      {!isPlaying && isReady && shouldShowPlayButton && (
        <PlayContainer onClick={handlePlay}>
          <PosterImg src={PosterJpg} />
          <PlayButton />
        </PlayContainer>
      )}
      {isMuted && isReady && <VolumeOffButton onClick={handleUnmute} />}
    </Container>
  )
}

const PosterImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const VolumeOffImage = styled.img.attrs({
  src: VolumeOffButtonSvg,
  alt: 'Unmute',
})``

const VolumeOffButton = styled.button.attrs({
  children: <VolumeOffImage />,
})`
  border: none;
  background: none;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 60px;
  appearance: none;
  opacity: 0.75;
  transition: opacity 0.3s ease-in;

  &:hover {
    opacity: 1;
  }
`

const PlayImage = styled.img.attrs({
  src: PlayButtonSvg,
  alt: 'Play',
})`
  width: 100%;
  height: auto;
  filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
`

const PlayButton = styled.button.attrs({
  children: <PlayImage />,
})`
  width: 120px;
  height: 120px;
  cursor: pointer;
  background: none;
  border: none;
  opacity: 0.75;
  appearance: none;
  outline: none;

  ${breakpoints.lessThan('tablet')`
    width: 60px;
    height: 60px;
  `}

  &:hover {
    opacity: 0.99;
  }
`

const PlayContainer = styled.div`
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*  */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 52.5rem;
  margin: 2.875rem 0;

  &::before {
    display: block;
    padding-top: calc(9 / 16 * 100%);
    content: '';
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    background-color: #000;
  }
`

export default Video
