import React from 'react'
import styled from 'styled-components'

import { isCurtainDownOnLoad } from 'context/StateContext'

const YouTubeVideo = () => {
  return (
    <Container>
      <Iframe />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 52.5rem;
  margin: 2.875rem 0;

  &::before {
    display: block;
    padding-top: calc(9 / 16 * 100%);
    content: '';
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Iframe = styled.iframe.attrs({
  title: 'InterviewVideo',
  width: 560,
  height: 315,
  src: `https://www.youtube.com/embed/OdTjzP-qkFU?modestbranding=1&rel=0&autoplay=${
    isCurtainDownOnLoad ? '1' : '0'
  }&controls=0`,
  frameBorder: 0,
  allow:
    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
  allowFullScreen: true,
})``

export default YouTubeVideo
