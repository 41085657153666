import React from 'react'
import styled from 'styled-components'
import breakpoints from 'constants/breakpoints'

const Cutout = () => (
  <Container>
    <p>
      <em>WSJ. Magazine</em> editor in chief <span>Kristina O&rsquo;Neill</span>{' '}
      speaks to <span>Carolyn Murphy</span>, <span>Shalom Harlow</span> and{' '}
      <span>Amber Valletta</span>—the iconic trio of models—about their 30-year
      friendship, their staying power in the fashion industry and their hope for
      brighter days ahead.
    </p>
  </Container>
)

const Container = styled.div`
  background-color: #e4e2df;
  width: 100%;
  padding: 2.5rem 5rem;
  margin: 3rem 0 3rem;

  p {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;

    ${breakpoints.greaterThan('tablet')`
      em,
      span {
        white-space: nowrap;
      }
    `}
`

export default Cutout
