import React from 'react'
import styled from 'styled-components'

import breakpoints from 'constants/breakpoints'

const Heading = ({ children }) => (
  <Container>
    <Spacer />
    <H2>{children}</H2>
    <Spacer />
  </Container>
)

const Container = styled.div`
  max-width: 52.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 3.625rem;
  line-height: 1;
  margin-bottom: 1.5625rem;
`

const H2 = styled.h2`
  text-transform: uppercase;
  line-height: 1;
  font-size: 1rem;
  text-align: center;

  ${breakpoints.lessThan("tablet")`
    font-size: 0.8rem;
  `}
`

const Spacer = styled.span`
  border: 0 solid #000;
  border-bottom-width: 1px;
  flex: 1;
  margin: 0 0.5rem;
`

export default Heading
