import React from 'react'
import styled from 'styled-components'

import breakpoints from 'constants/breakpoints'

import Logo from './logo.png'
import LogoRetina from './logo@2x.png'

const SponsorHeader = () => (
  <Link
    href="http://go.mytheresa.com/707fuq"
    target="_blank"
    rel="noreferrer noopener"
  >
    <Img
      alt="MyTheresa Logo"
      src={Logo}
      srcSet={`${Logo} 1x, ${LogoRetina} 2x`}
      width="320"
      height="64"
    />
  </Link>
)

const Link = styled.a`
  display: block;
  position: absolute;
  top: 0.625rem;
  left: 1rem;
`

const Img = styled.img`
  width: 12.5rem;
  height: auto;

  ${breakpoints.lessThan('tablet')`
    width: 8rem;
  `}
`

export default SponsorHeader
