import React from 'react'
import styled from 'styled-components'

const THE_YEAR = (new Date()).getFullYear()

const Footer = () => (
  <Container>
    <p>Illustrations by Ryan McAmis</p>
    <p>© Copyright {THE_YEAR} Dow Jones &amp; Company, Inc. All Rights Reserved.</p>
    <p>
      <a
        href="https://www.dowjones.com/privacy-notice/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Notice
      </a>
      {' '}|{' '}
      <a
        href="https://www.dowjones.com/cookie-notice/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cookie Notice
      </a>
    </p>
    <p>
      <a
        href="mailto:eventsrsvp@wsj.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        eventsrsvp@wsj.com
      </a>
    </p>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.625rem 1.875rem;
  background-color: #272428;
  color: var(--color-white);
  align-items: center;
  text-align: center;

  p {
    margin-bottom: 0.5rem;
  }
`

export default Footer
