import { generateMedia } from "styled-media-query"

export const sizes = {
  mobileSmall: 320,
  mobile: 450,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1440,
}

const breakpoints = generateMedia({
  mobileSmall: `${sizes.mobileSmall}px`,
  mobile: `${sizes.mobile}px`,
  tablet: `${sizes.tablet}px`,
  tabletLandscape: `${sizes.tabletLandscape}px`,
  desktop: `${sizes.desktop}px`,
  desktopLarge: `${sizes.desktopLarge}px`,
})

export default breakpoints
