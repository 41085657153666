import React, { useContext } from 'react'
import styled from 'styled-components'
import breakpoints from 'constants/breakpoints'

import PosterPNG from './poster.gif'
import AnimationVideo from './Models.mp4'
import { StateContext } from 'context/StateContext'

const Animation = () => {
  const { isLive } = useContext(StateContext)

  if (isLive) return null

  return (
    <Container>
      <Video autoPlay loop muted playsInline poster={PosterPNG}>
        <source
          src={AnimationVideo}
          type="video/mp4"
          width="1920"
          height="1080"
        />
      </Video>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 50rem;
  margin-top: -2rem;
  margin-bottom: 0.5rem;

  ${breakpoints.greaterThan('desktop')`
    max-width: 200rem;
  `}

  &::before {
    display: block;
    padding-top: calc(9 / 16 * 100%);
    content: '';
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const Video = styled.video`
  max-width: 100%;
  ${breakpoints.greaterThan('desktop')`
    width: 100%;
  `}
`
export default Animation
