import React, { useContext, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import ReactGA from 'react-ga'

import breakpoints from 'constants/breakpoints'
import { StateContext } from 'context/StateContext'

import Animation from 'components/Animation'
import Audio from 'components/Audio'
import Counter from 'components/Counter'
import Cutout from 'components/Cutout'
import Footer from 'components/Footer'
import Info from 'components/Info'
import Interviews from 'components/Interviews'
import Logo from 'components/Logo'
import RegisterButton from 'components/RegisterButton'
import Sponsor from 'components/Sponsor'
import SponsorHeader from 'components/SponsorHeader'
import Video from 'components/Video'
import YouTubeVideo from 'components/YouTubeVideo'
import './App.scss'

function App() {
  const { isLive, isCurtainDown } = useContext(StateContext)

  useEffect(() => {
    ReactGA.initialize('UA-189482230-1')
    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
    <div className="App">
      <GlobalStyle />

      <Container>
        <SponsorHeader />
        <Info />
        <Logo />
        {!isCurtainDown && <Video />}
        {isCurtainDown && <YouTubeVideo />}
        <Animation />
        <Counter />
        <RegisterButton />
        <Cutout />
        <Sponsor />
        <Interviews />
        {!isLive && <Audio />}
      </Container>

      <Footer />
    </div>
  )
}

const Container = styled.div`
  display: flex;
  max-width: 1044px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* * */
  padding: 4.25rem 1.875rem 6.25rem;
`

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%;

    ${breakpoints.lessThan('desktop')`
      font-size: 90%;
    `}

    ${breakpoints.lessThan('tablet')`
      font-size: 83.2%;
    `}

    ${breakpoints.lessThan('mobile')`
      font-size: 70.4%;
    `}

    ${breakpoints.lessThan('mobileSmall')`
      font-size: 64%;
    `}
  }
`

export default App
