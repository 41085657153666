import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import useInterval from "@use-it/interval"

import { StateContext } from 'context/StateContext'
import Heading from 'components/Heading'

import { VIDEO_TIMESTAMP, getNow } from 'context/StateContext'

const Counter = () => {
  const { isLive } = useContext(StateContext)
  const [timerInterval, setTimerInterval] = useState(1000)

  const getTimeRemaining = () => {
    const total = VIDEO_TIMESTAMP - getNow()

    if (total <= 0 && timerInterval) {
      setTimerInterval(null)
      return { total, days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor(total / (1000 * 60 * 60) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    }
  }

  const getFormattedTime = () => {
    const { total, days, hours, minutes, seconds } = getTimeRemaining()

    if (isNaN(total)) return '00:00:00:00'

    return (
      ("0" + days).slice(-2) +
          ":" +
        ("0" + hours).slice(-2) +
        ":" +
        ("0" + minutes).slice(-2) +
        ":" +
        ("0" + seconds).slice(-2)
    )
  }

  const [countdown, setcountdown] = useState(getFormattedTime())

  const updateClock = () => {
    setcountdown(getFormattedTime())
  }

  useInterval(() => {
    updateClock()
  }, timerInterval)

  if (isLive) return null

  return (
    <>
      <Heading>The conversation starts in</Heading>

      <Container>
        {countdown}
      </Container>
    </>
  )
}

const Container = styled.div`
  max-width: 52.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 3.625rem;
  line-height: 1;
  margin-bottom: 1.5625rem;
  user-select: none;
`

export default Counter
