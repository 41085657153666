import React from "react";
import styled from "styled-components";
import breakpoints from "constants/breakpoints";

const Logo = () => (
  <Container>
    <Img
      alt="The One logo"
      src="/image/logo.png"
      srcSet="/image/logo.png 1x, /image/logo@2x.png 2x"
      width="528"
      height="116"
    />
  </Container>
);

const Container = styled.div`
  padding: 0 8.5rem;
  z-index: 1;

  ${breakpoints.lessThan("tablet")`
    padding: 0;
  `}
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
`;

export default Logo;
