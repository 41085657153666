import React, { useEffect, useState, createContext } from "react";
import axios from "axios";

export const ServerTimeContext = createContext();

export const ServerTimeProvider = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getServerTime = async () => {
      try {
        const { data } = await axios.get(
          'https://7e1q6qupq6.execute-api.us-east-2.amazonaws.com/time'
        );

        localStorage.setItem("SERVER_TIME_OFFSET", Date.now() - data, { path: "/" });
      } catch (error) {
        localStorage.setItem("SERVER_TIME_OFFSET", 0, { path: "/" });
      }

      setLoaded(true);
    }

    if (loaded) return;

    getServerTime();
  }, [loaded]);

  return (
    <ServerTimeContext.Provider value={null}>
      {loaded && props.children}
    </ServerTimeContext.Provider>
  );
};
