import React from "react"
import styled from 'styled-components'

import HeaderInfoBlackSrc from "./header-info-black.svg"
import HeaderInfoWhiteSrc from "./header-info-black.svg"

const Info = ({ color = "black" }) => (
  <Container>
    <span>
      <img
        src={color === "black" ? HeaderInfoBlackSrc : HeaderInfoWhiteSrc}
        alt="Information"
      />
    </span>

    <Tooltip>
      <p>
        Contact{" "}
        <a href="mailto:wsjtheone@thebos.co" target="_blank" rel="noreferrer">
          wsjtheone@thebos.co
        </a>{" "}
        for assistance
      </p>
    </Tooltip>
  </Container>
)

const Tooltip = styled.div`
  text-align: center;
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px;
  pointer-events: none;
  font-family: DJ5EscrowText;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  transition: opacity 0.3s;
  z-index: 2;

  p {
    max-width: 60ch;
  }

  a {
    text-decoration: underline;
  }
`

const Container = styled.div`
  position: absolute;
  top: 0.625rem;
  right: 1rem;

  span {
    display: block;
    cursor: pointer;
    padding-bottom: 0.5rem;
  }

  img {
    cursor: pointer;
    transition: opacity 0.3s;
  }

  &:hover {
    img {
      opacity: 0.7;
    }

    ${Tooltip} {
      opacity: 1;
      pointer-events: initial;
    }
  }
`

export default Info
