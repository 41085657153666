import React, { useState, createContext } from 'react'
import useInterval from '@use-it/interval'
import QueryString from 'query-string'
export const StateContext = createContext()

const getServerTimeOffset = () =>
  parseInt(localStorage.getItem('SERVER_TIME_OFFSET') || 0)
const { time, debug } = QueryString.parse(window.location.search)

export const getNow = () => Date.now() - getServerTimeOffset()

const DATE_NOW = getNow()
export const VIDEO_TIMESTAMP =
  time && debug ? DATE_NOW + time * 1000 : 1621893600000
const VIDEO_LENGTH = 2445 * 1000

const getIsLive = () => getNow() - VIDEO_TIMESTAMP >= 0
const initialIsLive = getIsLive()

const getHideRegister = () =>
  getNow() - VIDEO_TIMESTAMP + 2 * 60 * 60 * 1000 >= 0
const initialHideRegister = getHideRegister()

export const isCurtainDownOnLoad = DATE_NOW - VIDEO_TIMESTAMP > VIDEO_LENGTH

export const StateProvider = (props) => {
  const [isLive, setIsLive] = useState(initialIsLive)
  const [hideRegister, setHideRegister] = useState(initialHideRegister)
  const [isCurtainDown, setIsCurtainDown] = useState(isCurtainDownOnLoad)

  useInterval(
    () => {
      const newIsLive = getIsLive()
      if (newIsLive !== isLive) setIsLive(newIsLive)

      const newHideRegister = getHideRegister()
      if (newHideRegister !== hideRegister) setHideRegister(newHideRegister)
    },
    isLive ? null : 500
  )

  return (
    <StateContext.Provider
      value={{ isLive, hideRegister, isCurtainDown, setIsCurtainDown }}
    >
      {props.children}
    </StateContext.Provider>
  )
}
