import React from 'react'
import styled from 'styled-components'

import breakpoints from 'constants/breakpoints'
import Heading from 'components/Heading'
import Logo from './logo.png'
import LogoRetina from './logo@2x.png'

const Sponsor = () => (
  <>
    <Heading>Proudly Sponsored By</Heading>
    <Col>
      <Link
        href="http://go.mytheresa.com/707fuq"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          alt="MyTheresa Logo"
          src={Logo}
          srcSet={`${Logo} 1x, ${LogoRetina} 2x`}
          width="400"
          height="79"
        />
      </Link>
      <Graf>
        Mytheresa is a leading global e-commerce destination for luxury fashion,
        offering over 250 of the world’s most coveted international luxury
        brands with a focus on exclusive brand collaborations and curated
        product selection including Bottega Veneta, Burberry, Dolce & Gabbana,
        Fendi, Gucci, Loewe, Loro Piana, Moncler, Saint Laurent and Valentino.
        Express worldwide shipping to over 130 countries, complete with
        signature luxury packaging, unparalleled, personalized customer care and
        dedicated personal shopping service—luxury fashion at the click of a
        button.
      </Graf>
    </Col>
  </>
)

const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  max-width: 45rem;
`

const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity ease-in-out 0.3s;
  margin-top: 1.5rem;

  &:hover {
    opacity: 0.3;
  }
`

const Img = styled.img`
  max-width: 17.5rem;
  height: auto;
`

const Graf = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
  margin-top: 1.75rem;
  margin-bottom: 0;

  ${breakpoints.greaterThan('tablet')`
    em,
    span {
      white-space: nowrap;
    }
  `}
`

export default Sponsor
