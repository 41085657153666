import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import music from "./music.mp3";
import { ReactComponent as Mute } from "./mute.svg";
import { ReactComponent as Unmute } from "./unmute.svg";

const playlist = [music];

const Audio = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const audioRef = useRef();

  const handleClick = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleEnded = () => {
    if (playlist.length - 1 <= currentSongIndex) {
      setCurrentSongIndex(0);
    } else {
      setCurrentSongIndex(currentSongIndex + 1);
    }
    audioRef.current.src = playlist[currentSongIndex];
    audioRef.current.play();
  };

  useEffect(() => {
    try {
      audioRef.current.play();
    } catch (error) {
      console.log(error);
    }
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Container
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 0.5, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: [0.34, 0.2, 0.1, 1] }}
          isPlaying={isPlaying}
          onClick={handleClick}
        >
          {!isPlaying ? <Mute /> : <Unmute />}
        </Container>
      ) : null}

      <AudioEl
        ref={audioRef}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
      >
        <source src={playlist[currentSongIndex]} type="audio/mpeg" />
      </AudioEl>
    </>
  );
};

const Container = styled(motion.div)`
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 100;
  position: fixed;
  height: 2.75rem;
  width: 2.75rem;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in;

  ${({ isPlaying }) => !isPlaying && `padding-right: 0.3rem;`}

  &:hover {
    opacity: 1 !important;
  }

  > svg {
    display: block;
    height: auto;
    width: 100%;
    filter: drop-shadow(3px 3px 2px rgba(255, 255, 255, 0.7));
  }
`;

const AudioEl = styled.audio`
  display: none;
`;

export default Audio;
