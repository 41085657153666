import React from 'react'
import styled from 'styled-components'

import Heading from 'components/Heading'
import KidmanPortrait from './portrait_kidman.png'
import KidmanPortraitRetina from './portrait_kidman@2x.png'
import GrahamPortrait from './portrait_graham.png'
import GrahamPortraitRetina from './portrait_graham@2x.png'
import JennerPortrait from './portrait_jenner.png'
import JennerPortraitRetina from './portrait_jenner@2x.png'
const Interviews = () => (
  <>
    <Heading>More interviews</Heading>
    <Row>
      <Link
        href="https://youtu.be/6x11KLKYEB0"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          alt="Nicole Kidman Interview"
          src={KidmanPortrait}
          srcSet={`${KidmanPortrait} 1x, ${KidmanPortraitRetina} 2x`}
          width="245"
          height="187"
        />

        <H3>
          Nicole <br />
          Kidman
        </H3>
      </Link>

      <Link
        href="https://youtu.be/7Oe9FJo4P_E"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          alt="Ashley Graham Interview"
          src={GrahamPortrait}
          srcSet={`${GrahamPortrait} 1x, ${GrahamPortraitRetina} 2x`}
          width="245"
          height="187"
        />

        <H3>
          Ashley <br />
          Graham
        </H3>
      </Link>
      <Link
        href="https://youtu.be/pRDmXg6xJ1I"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          alt="Kris Jenner Interview"
          src={JennerPortrait}
          srcSet={`${JennerPortrait} 1x, ${JennerPortraitRetina} 2x`}
          width="245"
          height="187"
        />

        <H3>
          Kris
          <br /> Jenner
        </H3>
      </Link>
    </Row>
  </>
)

const Row = styled.div`
  width: 100%;
  max-width: 52.5rem;
  display: flex;
  justify-content: center;
`

const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity ease-in-out 0.3s;
  margin: 0 2rem;

  &:hover {
    opacity: 0.3;
  }
`

const H3 = styled.h3`
  margin-top: 1.5rem;
  font-size: 1.25rem;
  letter-spacing: 0.125rem;
  text-align: center;
`

const Img = styled.img`
  height: auto;
`

export default Interviews
